// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import { createApp } from 'vue';
import {
  UserLoadSite, initAuth, getUser, ModelFactory, AppAxiosInstance, BehaviourFactory,
  AppModel,
  baseRoutes,
  ControlModelFactory,
} from '@quickdrawltd-org/eden/src/main';
// * as AmplifyModules
import VueApexCharts from 'vue-apexcharts';
import App from './App.vue';
import Router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';

let envPath = '.env';

const mode = process.env.NODE_ENV;
if (mode) {
  if (mode.includes('cd')) {
    envPath = `.env.${mode}`;
  }
}
// eslint-disable-next-line @typescript-eslint/no-var-requires
require('dotenv').config({ path: envPath });

loadFonts();

window.onerror = function (msg, url, line, col, error) {
  console.log(`window.onerror:
  ${error?.toString()}`);
  AppAxiosInstance.getInstance().post('/automated_bug_reporting', {
    err: error?.toString(),
    route: Router.router.currentRoute.value.path,
    info: msg,
  }).catch((restErr) => {
    console.log(`err:::automated_bug_reporting::${JSON.stringify(restErr)}`);
  });
};

// const getCircularReplacer = (): any => {
//   const seen = new WeakSet();
//   return (key, value) => {
//     console.log(`key: ${key}`);
//     if (typeof value === 'object' && value !== null) {
//       if (seen.has(value)) {
//         return;
//       }
//       seen.add(value);
//     }
//     return value;
//   };
// };

const errorHandler = (err, vm, info) => {
  console.log(`vue.errorHandler:
  err: ${err.toString()}
  info: ${info.toString()}
  vm: ${vm.$data}`);

  AppAxiosInstance.getInstance().post('/automated_bug_reporting', {
    err: err.toString(),
    route: Router.router.currentRoute.value.path,
    info,
  }).catch((restErr) => {
    console.log(`err:::automated_bug_reporting::${JSON.stringify(restErr)}`);
  });
};

const warnHandler = (err, vm, info) => {
  console.log(`vue.warnHandler:
  err: ${err.toString()}
  info: ${info.toString()}`);
  AppAxiosInstance.getInstance().post('/automated_bug_reporting', {
    err: err.toString(),
    route: Router.router.currentRoute.value.path,
    info,
  }).catch((restErr) => {
    console.log(`err:::automated_bug_reporting::${JSON.stringify(restErr)}`);
  });
};

const apiEndPoint = process.env.VUE_APP_API_ENDPOINT;
console.log(apiEndPoint);
const setupEverything = async function () {
  console.log('setupEverything');
  console.log('setupEverything');
  const apiEndPointX = process.env.VUE_APP_API_ENDPOINT;
  console.log(apiEndPointX);
  console.log(apiEndPointX);
  const siteAuth = await axios.get(`${apiEndPointX}/siteAuth`);
  if (!siteAuth.data) {
    return null;
  }
  const AppRouter = new Router();
  const app = createApp(App);
  app.use(Router.router);
  app.use(store);
  app.use(vuetify);

  // eslint-disable-next-line
  delete siteAuth.data.data._id;
  // eslint-disable-next-line
  delete siteAuth.data.data.setting_group;

  AppAxiosInstance.configure(Router.router, apiEndPoint);

  const baseResult = await baseRoutes.loadBaseRoutes();
  console.log('baseResult');
  console.log(JSON.stringify(baseResult, null, 2));
  AppModel.configure(store);
  const siteSettingResult = await baseRoutes.loadSiteSettings();
  try {
    AppRouter.setBaseRoutes(baseResult);
  } catch (e) {
    console.log(e);
  }

  // eslint-disable-next-line no-new
  new ModelFactory(Router.router);
  // eslint-disable-next-line no-new
  new BehaviourFactory(Router.router);
  // eslint-disable-next-line no-new
  new ControlModelFactory(Router.router);
  app.mount('#app');
  await initAuth(siteAuth.data.data);
  const user = await getUser();

  if (user) {
    const userLoadSite = new UserLoadSite(Router.router);
    console.log(Router.router.currentRoute.value.path);
    let redirect = '';
    if (Router.router.currentRoute.value.path === '/') {
      redirect = '/home';
    } else {
      redirect = Router.router.currentRoute.value.path;
    }
    userLoadSite.Load(user, redirect);
  }
  return 'done';
};

await setupEverything();
